//专门的路由配置文件
export default [
    {
        //首页
        path: '/',//在8080基础上的路径../views/lagout.vue
        name: 'Layout',
        redirect: '/home',
        component: () => import("../views/Layout"),//后缀可以省略文件地址
        // 默认显示的页面
        children: [
            {
                //首页
                path: 'home',//在8080基础上的路径../views/lagout.vue
                name: 'Home',
                component: () => import("../views/Home"),
            },
            {
                //报名参加
                path: 'application',
                name: 'Application',
                component: () => import("../views/Application")
            },
            {
                //大会报告
                path: 'report',
                name: 'Report',
                component: () => import("../views/Report")
            },
            {
                //专题征稿
                path: 'solicit',
                name: 'Solicit',
                component: () => import("../views/solicitContributions")
            },
            {
                //日程安排
                path: 'schedule',
                name: 'Schedule',
                component: () => import("../views/Schedule")
            },
            {
                //联系方式
                path: 'contact',
                name: 'Contact',
                component: () => import("../views/contactInformation")
            }
        ]
    },
    {
        //404页面放在最后在前面所以页面无法进入时才会执行
        path: '*',
        name: '404',
        component: () => import("../views/404")
    }
]
