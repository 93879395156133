<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    updateMetaTags() {
      // 更新 meta 标签的内容
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.keywords);
      } else {
        const newMetaKeywords = document.createElement('meta');
        newMetaKeywords.setAttribute('name', 'keywords');
        newMetaKeywords.setAttribute('content', this.keywords);
        document.head.appendChild(newMetaKeywords);
      }

      // 更新描述 meta 标签的内容
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.description);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', 'description');
        newMetaDescription.setAttribute('content', this.description);
        document.head.appendChild(newMetaDescription);
      }
    }
  }
}
</script>
<style lang="less"></style>
