import { get } from '../utils/http'
import { getFileAccessHttpUrl } from '@/api/manage'
export const myMixin = {
    data() {
        return {
            keywords: '',
            description: '',
            deadline4: null,
            Alldata: {},
            showList: [],
            // 全部的导航栏
            allList: [
                { id: 0, name: '首页', url: '/home' },
                { id: 1, name: '报名参加', url: '/application' },
                { id: 2, name: '大会报告', url: '/report' },
                { id: 3, name: '专题征稿', url: '/solicit' },
                { id: 4, name: '日程安排', url: '/schedule' },
                { id: 5, name: '联系方式', url: '/contact' },
            ],
            // 显示的导航栏
            navList: [
                { id: 0, name: '首页', url: '/home' },
            ],
            partList: [
                {
                    name: '联系方式',
                    id: 5,
                    state: false
                },
                {
                    name: '会议简介',
                    id: 6,
                    state: false
                },
                {
                    name: '时间与地点',
                    id: 7,
                    state: false
                },
                {
                    name: '会议学术委员会',
                    id: 8,
                    state: false
                },
                {
                    name: '会议组织委员会',
                    id: 9,
                    state: false
                },
            ],
            startTime: '',
            AcademicList: {
                position: [],
                member: []
            },
            OrganizingList: {
                position: [],
                member: [],
                secretary: []
            },
            conferenceSponsor: {},
            conferenceContactWay: {}
        }
    },
    methods: {
        getDate() {
            get('/oa/tbConference/selectTbConferenceByStatus').then(res => {
                if (res.code == 200) {
                    this.Alldata = res.result
                    this.banner = this.Alldata.backgroundImageUrl
                    this.theme_img = this.Alldata.conferenceIntroductionUrl

                    if (window.location.pathname == '/solicit') {
                        this.processingDataSolicit()
                    } else if (window.location.pathname == '/schedule') {
                        this.processingDataSchedule()
                    }
                } else {
                    this.$message.error(res.msg);
                }
            }).finally(() => {
                this.processingData()
            })
        },
        getImgView(img) {
            if (img && img.indexOf(",") > 0) {
                img = img.substring(0, img.indexOf(","))
            }
            return getFileAccessHttpUrl(img)
        },
        processingData() {
            this.showList = this.Alldata.sectorManagement
            this.logo = this.Alldata.logoUrl
            this.allList.forEach(itemA => {
                this.showList.forEach(itemB => {
                    if (itemA.id === itemB.id) {
                        this.navList.push(itemA);
                    }
                });
            });
            this.partList.forEach(itemA => {
                this.showList.forEach(itemB => {
                    if (itemA.id == itemB.id) {
                        itemA.state = true;
                    }
                });
            });

            // 使用正则表达式匹配第一个日期
            let match = this.Alldata.meetingTime.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/);

            if (match) {
                // 提取匹配到的日期
                let year = match[1];
                let month = match[2].padStart(2, '0');
                let day = match[3].padStart(2, '0');

                // 转换日期格式
                this.startTime = `${year}-${month}-${day}T00:00:00`;
                this.deadline4 = Date.now() + (new Date(this.startTime) - Date.now()),
                    console.log(this.startTime);
            } else {
                console.log("未找到日期");
            }
            this.Alldata.academic.forEach(item => {
                if (item.duties == '1') {
                    this.AcademicList.position.push(item)
                } else if (item.duties == '2') {
                    this.AcademicList.member.push(item)
                }
            })
            this.Alldata.organizing.forEach(item => {
                if (item.duties == '1') {
                    this.OrganizingList.position.push(item)
                } else if (item.duties == '3') {
                    this.OrganizingList.member.push(item)
                } else if (item.duties == '2') {
                    this.OrganizingList.secretary.push(item)
                }
            })
            this.conferenceSponsor = this.Alldata.conferenceSponsor
            this.conferenceContactWay = this.Alldata.conferenceContactWay
            this.keywords = this.Alldata.keywords
            this.description = this.Alldata.description
            this.updateMetaTags();
        },


    }
}