import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementUI from 'element-ui';
import './router/guard'
import 'element-ui/lib/theme-chalk/index.css';
import "./common/clearDefault.less"
import './common/standard.less'
import 'animate.css';
import { myMixin } from './mixins/myMixin.js'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.mixin(myMixin)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
