//axios二次封装
import axios from "axios"
import { Message } from "element-ui"
// import { getToken} from "./auth"//@代表src目录'@/utils/auth.js'
//创建axios实  例
const service=axios.create({
    // baseURL:"http://192.168.2.116:9999",//公共前缀
    baseURL:"https://www.springcity.top:9999",//公共前缀
    timeout:5000,//请求超过这个时间失效
})

service.interceptors.response.use(function(response){
    if(response.status==200){
        const res=response.data
        if(res.success){
            return res
        }else{
            Message({
                type:"error",
                message:res.message||"请求错误"
            });
            return Promise.reject(new Error(res.message||"请求错误"))
        }
    }  
}),function(error){
    Message({
        type:"error",
        message:error.message||"请求错误"
        // message:"发送请求失败"
    });
    return Promise.reject(new Error("发送请求失败"))
}
export default service 