//封装post和get两个请求
import service from "./service";
export function get(url,params){
    const config={
        method:"get",
        url
    }
    if(params){
        config.params=params//当有参数的时候在congfig中加params
    }
    return service(config)
}
export function post(url,params){
    const config={
        method:"post",
        url
    }
    if(params){
        config.data=params
    }
    return service(config)
}